<template>
  <div>
    <!-- Filters -->
    <b-card>
      <b-row
        align-v="center"
        align-h="between"
      >
        <!-- Inicio Contrato -->
        <b-col
          cols="12"
          lg="10"
          md="10"
        >
          <label>Proveedor</label>
          <v-select
            v-model="proveedorFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="proveedores"
            :clearable="false"
            :reduce="(val) => val.id"
            placeholder="Filtrar por proveedor"
            label="nombre_comercial"
          />
        </b-col>

        <!-- Filtrar -->
        <b-col
          cols="12"
          lg="2"
          md="2"
          class="pt-2"
        >
          <b-button
            v-if="!isFiltering"
            variant="outline-primary"
            class="mr-2"
            block
            @click="filterData"
          >
            Filtrar
            <feather-icon icon="FilterIcon" />
          </b-button>

          <b-button
            v-else
            variant="outline-danger"
            class="mr-2"
            block
            @click="clearFilter"
          >
            Limpiar
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                @click="editPedido('new')"
              >
                <span class="text-nowrap">Crear Pedido</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPedidosListTable"
        class="position-relative"
        :items="fetchPedidos"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchPedidos"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              class="align-middle"
              small
            />
            <strong class="ml-1">Cargando datos...</strong>
          </div>
        </template>

        <!-- Column: Priority -->
        <template #cell(is_urgent)="data">
          <b-badge :variant="data.item.is_urgent ? 'danger' : 'secondary'">
            {{ data.item.is_urgent ? "URGENTE" : "NORMAL" }}
          </b-badge>
        </template>

        <!-- Column: Codigo -->
        <template #cell(codigo)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="editPedido(data.item.id)"
          >
            #{{ data.item.codigo }}
          </b-link>
        </template>

        <!-- Column: Fecha -->
        <template #cell(fecha)="data">
          <span>{{ formatDate(data.item.fecha) }}</span>
        </template>

        <!-- Column: User Creado -->
        <template #cell(proveedor_name)="data">
          <router-link
            target="_blank"
            style="padding: 6px 0 0 6px"
            :to="{ name: 'proveedor-edit', params: { id: data.item.fk_proveedor } }"
          >
            <slot>{{ data.item.proveedor_name }}
              <feather-icon
                icon="ExternalLinkIcon"
                size="16"
                class="align-top"
              />
            </slot>
          </router-link>
        </template>

        <!-- Column: User Creado -->
        <template #cell(fk_user_creado)="data">
          <span>{{ data.item.user_creado_name }}</span>
        </template>

        <!-- Column: Estado -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.estado)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.estado) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row>
            <!-- Editar -->
            <b-button
              :id="`edit-${data.item.id}`"
              variant="success"
              class="btn-icon px-1 mr-1"
              @click="editPedido(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-tooltip
              :target="`edit-${data.item.id}`"
              triggers="hover"
              placement="bottom"
              boundary="window"
            >
              <b>Editar</b>
            </b-tooltip>

            <b-dropdown
              :id="`more-${data.item.id}`"
              right
              variant="info"
              size="sm"
              no-caret
              style="height: 36px"
            >
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" />
              </template>

              <!-- Imprimir -->
              <b-dropdown-item
                :id="`imprimir-${data.item.id}`"
                @click="imprimirPedido(data.item.id)"
              >
                <feather-icon
                  icon="PrinterIcon"
                  class="mr-1"
                />Imprimir</b-dropdown-item>

              <!-- Enviar -->
              <b-dropdown-item

                :id="`send-${data.item.id}`"
                variant="danger"
                @click="
                  sendModal = true;
                  selectedItem = data.item;
                "
              >
                <feather-icon
                  icon="SendIcon"
                  class="mr-1"
                />
                <span v-if="data.item.estado === 'borrador'">Enviar</span>
                <span v-else>Reenviar<br>Enviado {{ formatDate(null, data.item.fecha_enviado) }}</span>
              </b-dropdown-item>

              <!-- Eliminar -->
              <b-dropdown-item
                v-if="data.item.estado === 'borrador'"
                :id="`delete-${data.item.id}`"
                variant="danger"
                @click="
                  deleteModal = true;
                  selectedItem = data.item;
                "
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-1"
                />
                Eliminar</b-dropdown-item>
            </b-dropdown>

            <b-tooltip
              :target="`more-${data.item.id}`"
              triggers="hover"
              placement="bottom"
              boundary="window"
            >
              <b>Mas opciones</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de
              {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPedidos"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <ConfirmationModal
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      message="Esta acción no se puede revertir y se perderá el pedido."
      title="¿Está seguro de que desea eliminar el pedido?"
      @action="deletePedido(selectedItem.id)"
      @close="deleteModal = false"
    />

    <ConfirmationModal
      :id="`confirmation-send-${selectedItem.id}`"
      :show="sendModal"
      message="¿Quieres enviar un correo electrónico al proveedor con los detalles de tu pedido?"
      title="¿Está seguro?"
      @action="sendPedido(selectedItem.id)"
      @close="sendModal = false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import useProveedoresList from '@/views/system/proveedor/useProveedoresList'
import proveedorStoreModule from '@/views/system/proveedor/proveedorStoreModule'
import pedidosProveedoresStoreModule from './pedidosProveedoresStoreModule'
import usePedidosProveedoresList from './usePedidosProveedoresList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,
    BDropdown,
    BDropdownItem,

    vSelect,
    ConfirmationModal,
  },
  data() {
    return {
      deleteModal: false,
      sendModal: false,
      selectedItem: {},
    }
  },
  methods: {
    sendPedido(id) {
      this.sendModal = true
      this.$store
        .dispatch('app-pedidos-proveedores/sendPedidoProveedor', id)
        .then(() => {
          this.sendModal = false
          this.refetchData()
        })
    },
    editPedido(id) {
      this.$router.push({
        name: 'pedido-proveedor-edit',
        params: {
          id,
        },
      })
    },
    deletePedido(id) {
      this.deleteModal = true
      this.$store
        .dispatch('app-pedidos-proveedores/deletePedidoProveedor', id)
        .then(() => {
          this.deleteModal = false
          this.refetchData()
        })
    },
    imprimirPedido(id) {
      this.$store
        .dispatch('app-pedidos-proveedores/printPedidoProveedor', id)
        .then(response => {
          window.open(response.route, '_blank')
        })
        .catch(error => {
          console.error('Error printing the pedido:', error)
        })
    },
  },
  setup() {
    const PEDIDOS_PROVEEDORES_APP_STORE_MODULE_NAME = 'app-pedidos-proveedores'
    const PROVEEDOR_APP_STORE_MODULE_NAME = 'app-proveedor'

    // Register module
    if (!store.hasModule(PEDIDOS_PROVEEDORES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PEDIDOS_PROVEEDORES_APP_STORE_MODULE_NAME,
        pedidosProveedoresStoreModule,
      )
    }
    if (!store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROVEEDOR_APP_STORE_MODULE_NAME,
        proveedorStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PEDIDOS_PROVEEDORES_APP_STORE_MODULE_NAME)) store.unregisterModule(PEDIDOS_PROVEEDORES_APP_STORE_MODULE_NAME)
      if (store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME)) store.unregisterModule(PROVEEDOR_APP_STORE_MODULE_NAME)
    })

    const proveedores = ref([])

    const { listProveedores } = useProveedoresList()

    listProveedores().then(response => {
      proveedores.value = response
    })

    const {
      fetchPedidos,
      tableColumns,
      perPage,
      currentPage,
      totalPedidos,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPedidosListTable,
      refetchData,
      proveedorFilter,
      isFiltering,
      filterData,
      clearFilter,

      // UI
      resolveStatusVariant,
      resolveStatusText,
      parseDate,
    } = usePedidosProveedoresList()

    return {
      fetchPedidos,
      tableColumns,
      perPage,
      currentPage,
      totalPedidos,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPedidosListTable,
      refetchData,
      proveedorFilter,
      proveedores,
      isFiltering,
      filterData,
      clearFilter,
      // UI
      resolveStatusVariant,
      resolveStatusText,
      parseDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
